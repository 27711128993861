
import { Component, Vue } from 'vue-property-decorator'
import { Menu, Info, ButtonDetail } from '../../types/role'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'

@Component
export default class RoleAdd extends Vue {
  private info: Info = {
    roleName: '',
    orderNum: 1,
    description: '',
    dataPermission: '1',
    menuList: []
  }

  private rules = {
    roleName: [{ required: true, message: '请输入角色名称', trigger: ['blur', 'change'] }],
    orderNum: [
      { required: true, message: '请输入角色排序', trigger: ['blur', 'change'] },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ],
    dataPermission: [{ required: true, message: '请选择数据权限', trigger: ['change'] }],
    menuList: [{ required: true, message: '请选择勾选权限', trigger: ['blur'] }]
  }

  private menuList = []
  private checkedKeys: Array<string> = []
  private props = {
    value: 'menuId',
    children: 'childNode',
    label: 'menuName',
    disabled: (data: Menu) => {
      // 设置公用菜单置灰
      if (data.menuCommon === '1') {
        return true
      }
    }
  }

  // 按钮权限
  private buttonList: Array<ButtonDetail> = []
  private currentMenuId = ''
  private isCurrentChecked = false
  private isIndeterminate = false
  private checkAll = false

  private submitShow = false
  created () {
    this.menuGet()
    if (this.$route.params.id) {
      this.dataGet()
    } else {
      this.info.orderNum = Number(this.$route.params.orderNum) || 1
    }
  }

  // 详情数据
  dataGet () {
    this.$axios.get(this.$apis.role.roleDetail, {
      id: this.$route.params.id
    }).then((res) => {
      this.info = {
        roleName: res.roleName || '',
        orderNum: res.orderNum || 1,
        description: res.description || '',
        dataPermission: res.dataPermission || '',
        menuList: res.menuList || [],
        id: res.id,
        roleCatalog: res.roleCatalog
      }
    })
  }

  // 菜单数据
  menuGet () {
    this.$axios.get(this.$apis.role.roleMenu, {
      id: this.$route.params.id || null
    }).then((res) => {
      this.menuList = res || []
      this.checkedKeysSet(this.menuList)
    })
  }

  // 处理勾选数据
  checkedKeysSet (val: Array<Menu>) {
    val.map((item: Menu) => {
      if (item.childNode) {
        this.checkedKeysSet(item.childNode)
      } else {
        if (item.isSelect === '1') {
          this.checkedKeys.push(item.menuId)
        }
      }
    })
  }

  // 当复选框被点击的时候触发（会优先执行checkChange，所以不需要做数据处理）
  check (data: Menu) {
    if (!data.childNode) {
      this.currentMenuId = data.menuId
      this.buttonList = data.button || []
      this.updateAllCheckedStatus()
    }
  }

  // 节点选中状态发生变化时(优先于check执行），主菜单变更会触发子菜单变更（主菜单变更会调用多次）
  checkChange (data: Menu, check: boolean) {
    if (!data.childNode) {
      const buttonList = data.button || []
      data.button = buttonList.map(item => {
        item.isSelect = check
        return item
      })
      if (data.menuId === this.currentMenuId) {
        this.updateAllCheckedStatus()
      }
    }
  }

  // 菜单被点击
  treeClick (data: Menu) {
    if (!data.childNode) {
      this.currentMenuId = data.menuId
      this.buttonList = data.button || []
      this.updateAllCheckedStatus()
    }
  }

  // 更改按钮权限全选状态
  updateAllCheckedStatus () {
    const checkedKeys: Array<string> = (this.$refs.tree as HTMLFormElement).getCheckedKeys(true)
    this.isCurrentChecked = checkedKeys.findIndex(item => item === this.currentMenuId) >= 0
    let checkedCount = 0
    this.buttonList.forEach(item => {
      if (item.isSelect) {
        checkedCount++
      }
    })
    this.checkAll = checkedCount === this.buttonList.length
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.buttonList.length
  }

  // 按钮全选或全不选
  handleCheckAllChange (val: boolean) {
    if (val) {
      this.buttonList = this.buttonList.map(item => {
        item.isSelect = true
        return item
      })
    } else {
      this.buttonList = this.buttonList.map(item => {
        item.isSelect = false
        return item
      })
    }
    this.isIndeterminate = false
  }

  onSubmit () {
    this.info.menuList = []
    // 获取已勾选菜单
    const checkList = (this.$refs.tree as HTMLFormElement).getCheckedNodes(false, true)
    checkList.map((item: Menu) => {
      const buttonList: { butCode: string }[] = []
      if (item.button) {
        item.button.map((buttonItem: { butCode: string; isSelect: boolean }) => { // 按钮权限
          if (buttonItem.isSelect) {
            buttonList.push({ butCode: buttonItem.butCode })
          }
        })
      }
      this.info.menuList.push({
        menuId: item.menuId,
        button: buttonList
      })
    });
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.save()
      }
    })
  }

  save () {
    this.submitShow = true
    const url = this.$route.params.id ? this.$apis.role.roleUpdate : this.$apis.role.roleAdd
    this.$axios.post(url, this.info)
      .then(() => {
        this.$message({ message: '保存成功', type: 'success' })
        this.$router.push({ name: 'role' })
      })
      .catch(() => {
        this.submitShow = false
      })
  }
}
